.square-container {
  max-width: 100%; /* Allow the container to adjust to its parent's width */
  height: 0; /* Create a height that's a percentage of the width (100% for a square) */
  padding-bottom: 100%; /* Maintain a 1:1 aspect ratio (square) */
  overflow: hidden; /* Hide the parts of the image outside the container */
  position: relative; /* Ensure the child image is positioned correctly */
}

.square-image {
  object-fit: cover; /* Scale the image to cover the container without stretching */
  width: 100%; /* Make the image 100% width of the container */
  height: 100%; /* Make the image 100% height of the container */
  position: absolute; /* Position the image absolutely within the container */
  top: 0;
  left: 0;
  border-radius: 100%;
}

.nav-link.active {
  font-weight: bold;
}

.full-width-height-modal {
  position: fixed;
  margin: 0;
  top: 0px;
  left: 0;
  padding: 0px;
  max-width: 100%;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.full-screen-modal-content {
  width: 100%;
  height: 100vh;
  max-width: 100%;
  max-height: 100%;
  padding: 0;
  margin: 0;
}

.box-container {
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
  background-position: center;
}

.BackgroundImageBox-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px;
  max-height: 150px; /* Adjust this value to your desired container height */
  overflow-y: auto;
}

.BackgroundImageBox {
  background-color: lightgray;
  height: 60px;
  text-align: center;
  border: 1px solid #ccc;
  background-size: cover;
  background-position: center;
}
/* Remove outline on input focus */
.no-focus-outline:focus {
  outline: none;
}
/* ---------------------------- Loader Start ---------------------------------- */
.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.loader:before {
  /* content: attr(title); */
  content: 'Loading the magic...🌟';
  color: #fff;
  position: absolute;
  top: calc(50% + 150px);
  left: calc(50% - 90px);
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  font-size: 1.5rem;
}
.loader::after {
  content: '';
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border: 6px solid transparent;
  border-top: 6px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* ---------------------------- Loader End ---------------------------------- */

.pwa-container {
  position: fixed;
  bottom: 40px;
  right: 0;
  z-index: 3;
}

footer {
  position: fixed;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  text-align: center;
}
/* Initially hide the mobile menu */
#myNav {
  display: none;
}

/* Add styles for the "open" class to show the menu */
#myNav.open {
  display: block;
  /* Add other styles as needed for positioning and appearance */
}

.megamenu {
  position: static;
}

.megamenu .dropdown-menu {
  background: none;
  border: none;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.report-section .dropdown-menu {
  margin-left:-90px !important;
  width: 90%;
  /* transform: translate(15px, 279px) !important; */
}

code {
  color: #745eb1;
  background: #fff;
  padding: 0.1rem 0.2rem;
  border-radius: 0.2rem;
}

.text-uppercase {
  letter-spacing: 0.08em;
}
.megaimaga {
  background: center center
    url('https://therichpost.com/wp-content/uploads/2021/11/mega.png') no-repeat;
  background-size: cover;
}
.hamburger-profile .circular-icon {
  color: #fff;
  border-radius: 100%;
  padding: 8px;
  margin: 0 8px;
  width: 30px;
  height: 30px;
  padding: 10px;
}

.wallpaper-thumbnail {
  height: 380px;
  /* max-height: 380px; */
  max-width: 100%;
  margin: 10px;
  max-width: 100%;
  position: relative;
  cursor: zoom-in;
}

.wallpaper-thumbnail img {
  height: 300px;
}

.wallpaper-thumbnail a {
  font-weight: 100;
}

.wallpaper-thumbnail-before {
  /* position: absolute; */
  display: block;
  /* left: 0;
  top: 0;
  z-index: 1; */
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  padding: 5px;
  text-align: left;
  font-weight: bold;
}
.wallpaper-thumbnail-after {
  /* position: absolute; */
  display: block;
  /* left: 0;
  bottom: 0;
  z-index: 1; */
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  padding: 5px;
  text-align: center;
}

.wallpaper-thumbnail-center {
  position: absolute;
  right: 5px;
  top: 0px;
  font-size: 18px;
  z-index: 2;
  padding: 4px 4px;
  text-align: center;
}

.wallpaper-thumbnail-similar {
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 18px;
  z-index: 2;
  padding: 4px 4px;
  text-align: center;
}

.vertical-divider {
  border-left: 1px solid #ccc; /* Adjust the color and thickness as needed */
  height: 100%;
}

@media (max-width: 576px) {
  .ScrollableLeftPanelRight {
    overflow: auto;
    height: 45vh;
  }

  .ScrollableLeftPanelLeft {
    overflow-x: auto;
    height: 35vh;
  }
  .update-wallpaper .ScrollableLeftPanelRight {
    height: 65vh;
  }
  .update-wallpaper .ScrollableLeftPanelLeft {
    height: 20vh;
  }

  .pwa-container {
    width: 100%;
  }
}
